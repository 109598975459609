.privacy .container {
  max-width: 1200px;
  margin: auto;
  padding: 15px;
}
.privacy a {
  color: #0275d8;
}
.privacy a:hover,
.privacy a:focus {
  color: #0275d8;
}
/* http://developer.telerik.com/featured/300-ms-click-delay-ios-8/ */
.privacy a[href],
.privacy button {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* -------------------------------------------- */
.privacy .container h1,
.privacy .container .h1,
.privacy .container h2,
.privacy .container h3 {
  font-weight: 700;
  margin: 15px 0px 10px 0px;
}
.privacy .container h2 {
  font-size: 36px;
}
.privacy hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #ddd;
}
.privacy table,
.privacy td,
.privacy th {
  border: 1px solid black;
}

.privacy table {
  width: 100%;
  border-collapse: collapse;
}
.privacy table td,
.privacy table th {
  padding: 7px;
}

.privacy ol,
.privacy ul {
  padding: 10px 0px;
}

.privacy ol li,
.privacy ul li {
  margin-left: 30px;
}

.privacy ul ul,
.privacy ol ul,
.privacy ul ol,
.privacy ol ol,
.privacy li ul,
.privacy li ol {
  margin-left: 40px;
}

.privacy p {
  margin: 15px 0px;
}
