.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square .content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.text-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.searchLink {
  cursor: pointer;
}
.searchLink:hover {
  text-decoration: underline;
}
.cursorPointer {
  cursor: pointer;
}
.pos-abs {
  position: absolute;
}

.m-w-2x {
  max-width: 500px;
}

.mt-5 {
  margin-top: 5px;
}

.txtDecorNone a,
a.txtDecorNone {
  text-decoration: none !important;
}

.pswp-thumbnail {
  display: inline-block;
}

.pswp__caption {
  white-space: break-spaces;
}

.ribbon-danger {
  display: block;
  position: absolute;
  left: 20px;
  bottom: 25px;
  margin-left: -25px;
  margin-bottom: 5px;
}
.ribbon-danger:before {
  content: " ";
  border-style: solid;
  border-width: 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  margin-bottom: -3px;
  z-index: 1;
  border-color: #b3272c #b3272c transparent transparent;
}
.ribbon-danger .text {
  position: relative;
  display: block;
  padding: 4px 18px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #e53238;
  -webkit-font-smoothing: antialiased;
  -moz-box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 0 rgb(0 0 0 / 10%);
  -o-box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 0 rgb(0 0 0 / 10%);
  z-index: 1000;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-top-left-radius: 1px;
}

@media (max-width: 1200px) {
  .m-w-2x {
    max-width: 600px;
  }
}
@media (max-width: 1000px) {
  .m-w-2x {
    max-width: 500px;
  }
}
@media (max-width: 800px) {
  .m-w-2x {
    max-width: 400px;
  }
}
@media (max-width: 600px) {
  .m-w-2x {
    max-width: 300px;
  }
}
@media (max-width: 500px) {
  .m-w-2x {
    max-width: 250px;
  }
}
@media (max-width: 400px) {
  .m-w-2x {
    max-width: 200px;
  }
}
@media (max-width: 300px) {
  .m-w-2x {
    max-width: 150px;
  }
}

.txt-cap {
  text-transform: capitalize;
}

.color-white {
  color: white;
}

/* .App {
  padding: 15px;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.tooltip {
  position: relative;
  overflow: visible !important;
}
.tooltip:hover .tooltip-container {
  display: block;
}
.tooltip-container {
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  border-radius: 7px;
  max-width: 80vw;
  position: absolute;
  top: 100%;
  color: white;
  background-color: black;
  z-index: 999;
  display: none;
}
.tooltip-container span {
  display: block;
}
